import { useEffect } from "react";
import { Button } from "react-bootstrap";
import StackedMenu from 'stacked-menu/src/scripts/stacked-menu';
import $ from 'jquery';

import ccmLogoSmall from '@assets/images/CCM_logo_small.png';
import { IUser } from "@models";
import { AuthService, NavigationService, Toaster } from "@services";
import { CONSTS, ILoadingContext, useCurrentUser, useLoading, wrapInLoading } from "@utils";
import { GlobalLoaderSpinner } from "@components";

const LayoutHeader = () => {

  const loadingContext : ILoadingContext = useLoading();
  const userContext = useCurrentUser();

  useEffect(
    () => {
      let menu = new StackedMenu();
      $('.toggle-aside').click(function () {
        let isCompact = menu.isCompact();
        menu.compact(!isCompact);
        if (isCompact) {
          $('#app').removeClass('has-compact-menu');
        } else {
          $('#app').addClass('has-compact-menu');
        }
      });
    },
    // no deps => run once
    [])

  const logout = async () => await
    wrapInLoading(loadingContext.setLoading, AuthService.logout)
    .then(() => Toaster.makeSuccessToast('Вы успешно вышли из системы!'))
    .catch(() => setTimeout(() => NavigationService.navigateToLogin(), CONSTS.REDIRECT_TIMEOUT_MSEC));

  const renderName = (user: IUser) =>
    !user?.secondName
      ? ''
      : !user.firstName
        // second name only
        ? user.secondName
        : !user.middleName
          // second name + first letter of first name
          ? `${user.secondName} ${user.firstName[0]}.`
          // second name + first letter of first name + first letter of middle name
          : `${user.secondName} ${user.firstName[0]}. ${user.middleName[0]}.`;

  return (<header className="app-header app-header-light bg-light shadow-sm">
    <div className="top-bar">
      <div className="top-bar-brand d-flex justify-content-center">
        <Button className="hamburger hamburger-squeeze mr-2 toggle-aside" data-toggle="aside-menu" aria-label="toggle aside menu">
          <span className="hamburger-box">
            <span className="hamburger-inner"></span>
          </span>
        </Button>
        <a href="/">
          <img src={ccmLogoSmall} alt='Личный кабинет заказчика | ССМ' height="45" />
        </a>
      </div>
      <div className="top-bar-list">
        <div className="top-bar-item top-bar-item-right px-0">
          <GlobalLoaderSpinner />
          <div className="dropdown">
            <Button className="btn-account dropdown-toggle justify-content-end" id="dropdownMenuButton" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <span className="account-summary pr-lg-4 d-none d-lg-block">
                <span className="account-name">{renderName(userContext.currentUser)}</span>
              </span>
            </Button>
            <div className="user-menu dropdown-menu dropdown-menu-right dropdown-menu-xl-right" aria-labelledby="dropdownMenuButton">
              <div className="dropdown-arrow"></div>
              <Button className="dropdown-item"
                onClick={logout}>
                Выйти
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>);
}

export default LayoutHeader;
