import { useEffect, useState } from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import $ from 'jquery';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import '@assets/fonts/fontawesome/css/all.min.css'
import '@assets/fonts/fonts.css'
import '@assets/site.css';
import '@assets/theme.css';
import './App.css';

import { AuthBarrier } from '@components';
import { Layout, LoginPage, CompaniesPage, ToasterContainer } from '@views';
import { CurrentUserService, Toaster, UserDataService } from '@services';
import { IUser, IUserData } from '@models';
import { CONSTS, history, UserContext, LoadingContext, ILoadingContext, wrapInLoading } from '@utils';

interface AppState {
  currentUser: IUser | null
  userData: IUserData | null;
}

const showGenericError = () => Toaster.makeErrorToast(CONSTS.ERRORS.GENERIC);

const loadCurrentUser = () : IUser | null => CurrentUserService.get()

const loadUserData = async () : Promise<IUserData> => await UserDataService.get()

const loadAppState = async () : Promise<AppState> => ({
  currentUser: loadCurrentUser(),
  userData: await loadUserData(),
})

const App = () => {

  // initialize app loading state
  const [isLoading, setLoading] = useState(false);
  const loadingContext : ILoadingContext = { isLoading, setLoading };

  // initialize app data state
  const [appState, setAppState] = useState({
    currentUser: null,
    userData: null,
  })

  // update current user data in state
  const updateCurrentUser = async () : Promise<void> => setAppState((prevState) => ({
    ...prevState,
    currentUser: loadCurrentUser()
  }))

  // update user data in state
  const updateUserData = async () => await wrapInLoading(
    setLoading,
    async () => await loadUserData()
      .then(userData => setAppState((prevState) => ({
        ...prevState,
        userData: userData
      }))),
    showGenericError
  )

  // update whole state
  const updateAppState = async () => await wrapInLoading(
    setLoading,
    async () => await loadAppState().then(newState => setAppState(newState)),
    showGenericError
  )

  // run once
  useEffect(() => {
    $('[data-toggle="tooltip"]').tooltip();
    $('[data-toggle="popover"]').popover();
    updateAppState();
  },
  [])

  return (
    <LoadingContext.Provider value={loadingContext}>
      <UserContext.Provider value={{
        currentUser: appState.currentUser,
        userData: appState.userData,
        isUserAuthenticated: CurrentUserService.isUserAuthenticated,
        updateCurrentUser: updateCurrentUser,
        updateUserData: updateUserData
      }}>
        <ToasterContainer />
        <Router history={history}>
          <Switch>
            <Route path={CONSTS.PATHS.AUTH.LOGIN_PAGE} render={(props) =>
              <LoginPage {...props} />}
            />
            <Layout>
              <AuthBarrier exact path='/(|companies)/' component={CompaniesPage} />
            </Layout>
          </Switch>
        </Router>
      </UserContext.Provider>
    </LoadingContext.Provider>
  )
}

export default App;
