import React from "react";
import LayoutHeader from "./header";
import LayoutSidebar from "./sidebar";

import { GlobalLoadingLayer } from "@components";

interface LayoutProps {
  children: JSX.Element;
}

const Layout = ({ children }: LayoutProps) =>
  <>
    <LayoutHeader />
    <LayoutSidebar />
    <main className="app-main bg-white-2">
      <div className="wrapper">
        {children}
      </div>
      <GlobalLoadingLayer />
    </main>
  </>

export default Layout;
