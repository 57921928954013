import { ApiService, Data } from '@abstract';
import { IUserData } from '@models';
import { SessionTokenService } from '@services';
import { CONSTS } from '@utils';

const UserDataService = {
  get: async () : Promise<IUserData> => {
    let rawSessionToken = SessionTokenService.getRaw();
    if (!rawSessionToken) {
      return null;
    }

    let data = await ApiService.get<Data<IUserData>>({ url: CONSTS.PATHS.USER_DATA, accessToken: rawSessionToken });
    return data?.data;
  }
}

export default UserDataService;
