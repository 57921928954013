import { IUser } from '@models';
import { SessionTokenService } from '@services';


const CurrentUserService = {
  isUserAuthenticated: (): boolean => {
    const sessionToken = SessionTokenService.get();
    if (!sessionToken) {
      return false;
    }

    const currentTime = Date.now() / 1000;
    if (sessionToken.exp < currentTime) {
      return false;
    }

    return true;
  },

  get: (): IUser | null => {
    const sessionToken = SessionTokenService.get();
    if (!sessionToken) {
      return null;
    }

    return {
      secondName: sessionToken.family_name,
      firstName: sessionToken.given_name,
      middleName: sessionToken.middle_name,
      accessToken: sessionToken.rawToken
    };
  }
}

export default CurrentUserService;
