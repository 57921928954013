import { OfflinePaginatedItems } from "@components";
import { ICompany } from "@models";
import { anyCompany } from "./companies-utils";

interface CompaniesListProps {
  companies: ICompany[];
}

const CompaniesList = (props: CompaniesListProps) => {

  let companies = props.companies;

  return (
    <>
      {anyCompany(companies) &&
        <div className="list-group list-group-bordered">
          <OfflinePaginatedItems
            items={companies}
            itemsPerPage={10}
            renderItem={((company, i) =>
              <div className="list-group-item" key={i}>
                <div className="list-group-item-figure">
                  <i className="fas fa-building fa-2x text-primary"></i>
                </div>
                <div className="list-group-item-body">
                  <a href={'/companies/' + company.id + '/projects'} className="list-group-item-title text-uppercase">{company.name}<span className='text-lowercase'> (проектов: {company.projects.length})</span></a>
                </div>
              </div>)}
          />
        </div>
      }
      {!anyCompany(companies) &&
        <div className="alert alert-info">Нет доступных компаний</div>
      }
    </>
  )
}

export default CompaniesList;
