import { Dispatch, SetStateAction } from "react";

const wrapInLoading = async <TResult extends unknown>(
  setLoading: Dispatch<SetStateAction<boolean>>,
  callback: () => Promise<TResult>,
  onError: (error: any) => void = null,
) : Promise<TResult> =>
{
  setLoading(true);
  try {
    return await callback();
  }
  catch(error) {
    console.log(error);
    if (onError) {
      onError(error)
    }
  }
  finally {
    setLoading(false);
  }
}

export default wrapInLoading;
