import $ from 'jquery';

interface ToastOptions {
  delay: number;
}

const DEFAULT_TOAST_OPTIONS: ToastOptions = { delay: 2000 };
const INFO_TOAST_CLASSES = '';
const SUCCESS_TOAST_CLASSES = 'bg-success text-white';
const WARNING_TOAST_CLASSES = 'bg-warning text-black';
const ERROR_TOAST_CLASSES = 'bg-danger text-white';

const makeToast = (text: string, textConcatenatedClasses: string = '', toastOptions: ToastOptions = DEFAULT_TOAST_OPTIONS) => {
  let $toast = $(
    '<div class="toast" role="alert" aria-live="assertive" aria-atomic="true">' +
      '<div class="toast-body ' + textConcatenatedClasses + '">'
        + text +
      '</div>' +
    '</div>');
  $('#toasts').append($toast);
  $toast.toast(toastOptions);
  $toast.toast('show');
}

export const Toaster = {
  makeInfoToast: (text: string, toastOptions: ToastOptions = DEFAULT_TOAST_OPTIONS) =>
    makeToast(text, INFO_TOAST_CLASSES, toastOptions),

  makeSuccessToast: (text: string, toastOptions: ToastOptions = DEFAULT_TOAST_OPTIONS) =>
    makeToast(text, SUCCESS_TOAST_CLASSES, toastOptions),

  makeWarningToast: (text: string, toastOptions: ToastOptions = DEFAULT_TOAST_OPTIONS) =>
    makeToast(text, WARNING_TOAST_CLASSES, toastOptions),

  makeErrorToast: (text: string, toastOptions: ToastOptions = DEFAULT_TOAST_OPTIONS) =>
    makeToast(text, ERROR_TOAST_CLASSES, toastOptions),

  makeToast: makeToast
}

export type { ToastOptions }
