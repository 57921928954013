import { CONSTS, history } from "@utils";

const navigateToUrl = (url: string): Promise<void> => {
  history.push(url);
  return Promise.resolve();
}

const NavigationService = {
  navigateToRoot: () : Promise<void> => navigateToUrl(CONSTS.PATHS.ROOT),

  navigateToLogin: () : Promise<void> => navigateToUrl(CONSTS.PATHS.AUTH.LOGIN_PAGE),

  navigateToUrl: (url: string) : Promise<void> => navigateToUrl(url)
}

export default NavigationService;
