import Fuse from "fuse.js"
import { MutableRefObject, useEffect, useRef, useState } from "react"

interface OfflineSearchBarProps<TSearchItem> {
  allItems: TSearchItem[]
  colsSize: string
  placeholder: string
  itemKeysToSearch: string[]
  onItemsFound: (items: TSearchItem[]) => void
}

const OfflineSearchBar = <TSearchItem extends unknown>(props: OfflineSearchBarProps<TSearchItem>) => {

  const { allItems, onItemsFound, itemKeysToSearch } = props;

  const [searchTerm, setSearchTerm] = useState('');
  const fuse: MutableRefObject<Fuse<TSearchItem>> = useRef();

  useEffect(() => {
    fuse.current = new Fuse<TSearchItem>(allItems, { keys: itemKeysToSearch });
  }, [allItems, itemKeysToSearch]);

  // run on either search term or all items are updated
  useEffect(() => {
    if (!allItems || allItems.length === 0) {
      return;
    }

    if (searchTerm === '') {
      // reset
      onItemsFound(allItems)
    }

    if (searchTerm !== '') {
      let foundItems = fuse.current.search(searchTerm).map(({ item }) => item);
      onItemsFound(foundItems);
    }
  }, [ searchTerm, allItems, onItemsFound ])

  return (
    <div className={'search-bar col-' + props.colsSize}>
      <div className="d-flex justify-content-between">
        <div className="flex-fill">
          <div className="input-group">
            <input type="text" className="form-control" placeholder={props.placeholder} value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} />
            <div className="input-group-append">
              <button className="btn btn-subtle-primary pl-5 pr-5" type="button" onClick={() => setSearchTerm('')}>Очистить</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OfflineSearchBar;
