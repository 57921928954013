import { OfflineSearchBar } from '@components';
import { IUserContext, useCurrentUser } from '@utils';
import { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import CompaniesList from './companies-list';
import { anyEmptyCompany, filterOutEmptyCompanies, сompaniesCount } from './companies-utils';

const CompaniesPage = () => {

  const userContext: IUserContext = useCurrentUser();

  const [hideEmptyCompanies, setHideEmptyCompanies] = useState(true);
  const [allCompanies, setAllCompanies] = useState([]);
  const [displayedCompanies, setDisplayedCompanies] = useState([]);

  // companies from user data updated => need to reset companies in state
  useEffect(() => {
    setAllCompanies(userContext.userData?.companies);
    setDisplayedCompanies(userContext.userData?.companies);
  }, [userContext.userData?.companies])

  return (
    <div className='page p-3'>
      <div className='row'>
        <div className='col-12 mt-3 mb-3'>
          <span className='page-header text-uppercase'>
            Список компаний{allCompanies && allCompanies.length > 0 ? ` (${сompaniesCount(allCompanies, hideEmptyCompanies)})` : ''}
          </span>
        </div>
      </div>
      <div className='row mb-3'>
        <OfflineSearchBar
          allItems={allCompanies}
          colsSize='8'
          placeholder='Введите название компании'
          itemKeysToSearch={[ 'name' ]}
          onItemsFound={ setDisplayedCompanies }
        />
        {anyEmptyCompany(allCompanies) &&
          <div className='col-4 pt-2'>
            <Form.Check
              type='switch'
              id='hide-companies-with-no-projects'
              label='Не показывать компании без проектов'
              defaultChecked={hideEmptyCompanies}
              onChange={(e) => {
                setHideEmptyCompanies(e.target.checked)
              }}
            />
          </div>
        }
      </div>

      <CompaniesList companies={hideEmptyCompanies === true ? filterOutEmptyCompanies(displayedCompanies) : displayedCompanies} />
    </div>
  )
}

export default CompaniesPage;
