const CONSTS = {
  REDIRECT_TIMEOUT_MSEC: 1500,
  PATHS: {
    ROOT: '/',
    API_BASE_URL: 'api',
    AUTH: {
      LOGIN_PAGE: '/login',
      API: {
        LOGIN: 'auth/login',
        LOGOUT: 'auth/logout'
      }
    },
    USER_DATA: 'user-data',
  },
  ERRORS: {
    GENERIC: 'Произошла непредвиденная ошибка, мы уже разбираемся с проблемой. Пожалуйста, попробуйте позже.'
  }
}

export default CONSTS;
