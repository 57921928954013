import { CONSTS } from "@utils";

interface IHttpRequestOptions {
  url?: string;
  accessToken?: string;
}

interface IHasOptionalBody {
  body?: object;
}

interface IGetOptions extends IHttpRequestOptions, IHasOptionalBody {
}

interface IPostOptions extends IHttpRequestOptions, IHasOptionalBody {
}

interface IPutOptions extends IHttpRequestOptions, IHasOptionalBody {
}

interface IPatchOptions extends IHttpRequestOptions, IHasOptionalBody {
}

interface IDeleteOptions extends IHttpRequestOptions {
}

interface ApiError {
  statusCode: number;
  message: string;
}

export const isApiError = (arg: any) : arg is ApiError => arg && arg.statusCode && arg.message;

const prepareUrlEncodedBody = (body: any = null) => {
  let result = [];
  for (let property in body) {
    var encodedKey = encodeURIComponent(property);
    var encodedValue = encodeURIComponent(body[property]);
    result.push(encodedKey + "=" + encodedValue);
  }

  return result.join('&');
}

const handleError = (error) => {
  console.log(error);
  // it's might be just an empty response, which is fine => no need to scare user
  if (error.name !== 'SyntaxError') {
    // otherwise let's scare user with error
    throw error;
  }
}

const request = async <T extends unknown>(
  method: 'GET' | 'POST' | 'DELETE' | 'PUT' | 'PATCH' = 'GET',
  url: string,
  accessToken: string = null,
  body: any = null,
  isJson = true,
) : Promise<T>  =>
{
  const options = {
    method: method,
    headers: {
      'Content-Type': isJson ? 'application/json' : 'application/x-www-form-urlencoded',
      'Accept': 'application/json',
    },
  }

  if (method !== 'GET') {
    options['body'] = (body && isJson ? JSON.stringify(body) : prepareUrlEncodedBody(body)) || '';
  }

  if (accessToken) {
    options.headers['Authorization'] = `Bearer ${accessToken}`;
  }

  return await fetch(`/${CONSTS.PATHS.API_BASE_URL}/${url}`, options)
    .then(response =>
      response.ok
        ? response.json()
        : Promise.reject({
            statusCode: response.status,
            message: response.statusText
          } as ApiError))
    .catch(error => handleError(error));
}

export const ApiService = {
  get : async <T extends unknown>(options: IGetOptions) : Promise<T> => await request('GET', options.url, options.accessToken, options.body),
  post: async <T extends unknown>(options: IPostOptions) : Promise<T> => await request('POST', options.url, options.accessToken, options.body),
  put: async <T extends unknown>(options: IPutOptions) : Promise<T> => await request('PUT', options.url, options.accessToken, options.body),
  patch: async <T extends unknown>(options: IPatchOptions) : Promise<T> => await request('PATCH', options.url, options.accessToken, options.body),
  delete: async <T extends unknown>(options: IDeleteOptions) : Promise<T> => await request('DELETE', options.url, options.accessToken)
}

export type { IGetOptions, IPostOptions, IPutOptions, IPatchOptions, IDeleteOptions }
