import { IUser, IUserData } from "@models";
import React, { useContext } from "react";

interface IUserContextData {
  currentUser: IUser | null;
}

interface IUserContext {
  currentUser: IUser | null;
  userData: IUserData | null;
  isUserAuthenticated: () => boolean;
  updateCurrentUser: () => Promise<void>;
  updateUserData: () => Promise<void>;
}

const UserContext = React.createContext<IUserContext | null>(null)

const useCurrentUser = () : IUserContext => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error("useCurrentUser must be used within UserContext.Provider");
  }

  return context;
}

export { UserContext, useCurrentUser }
export type { IUserContext, IUserContextData }
