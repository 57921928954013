import jwt_decode from 'jwt-decode';

const TOKEN_KEY = 'lkz-auth-token';

interface ISessionToken {
  rawToken: string;
  jti: string;
  sub: string;
  exp: number;
  given_name: string;
  family_name: string;
  middle_name: string | null;
}

const getRawSessionToken = () : string | null => localStorage.getItem(TOKEN_KEY) || sessionStorage.getItem(TOKEN_KEY)

const SessionTokenService = {
  get: () : ISessionToken | null => {
    let rawSessionToken = getRawSessionToken();
    if (!rawSessionToken) {
      return null;
    }

    let decodedToken = jwt_decode<ISessionToken>(rawSessionToken);
    if (!decodedToken) {
      return null;
    }

    decodedToken.rawToken = rawSessionToken;
    return decodedToken;
  },

  getRaw: getRawSessionToken,

  saveTemporariliy: (token: string) : void => {
    sessionStorage.setItem(TOKEN_KEY, token);
    localStorage.removeItem(TOKEN_KEY);
  },

  savePersistently: (token: string) : void => {
    localStorage.setItem(TOKEN_KEY, token);
    sessionStorage.removeItem(TOKEN_KEY);
  },

  clear: () : void => {
    sessionStorage.removeItem(TOKEN_KEY);
    localStorage.removeItem(TOKEN_KEY);
  }
}

export default SessionTokenService;
export type { ISessionToken }
