import React, { useContext } from "react";

interface ILoadingContext {
  isLoading: boolean,
  setLoading: React.Dispatch<React.SetStateAction<boolean>>,
}

const LoadingContext = React.createContext<ILoadingContext | null>(null);

const useLoading = () : ILoadingContext => useContext(LoadingContext);

export { LoadingContext, useLoading }
export type { ILoadingContext }
