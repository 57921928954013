import { ICompany } from "@models";

const anyCompany = (companies: ICompany[]) : boolean => companies && companies.length > 0;

const isCompanyEmpty = (company: ICompany) : boolean => !company || !company.projects || company.projects.length === 0;

const filterOutNonEmptyCompanies = (companies: ICompany[]) : ICompany[] => companies.filter(isCompanyEmpty);

const filterOutEmptyCompanies = (companies: ICompany[]) :ICompany[] => companies?.filter(c => !isCompanyEmpty(c)) ?? [];

const anyEmptyCompany = (companies: ICompany[]) : boolean => anyCompany(companies) && filterOutNonEmptyCompanies(companies).length > 0;

const сompaniesCount = (companies: ICompany[], showNonEmptyOnly: boolean): number => !showNonEmptyOnly ? companies.length : filterOutEmptyCompanies(companies).length;

export { anyCompany, isCompanyEmpty, filterOutNonEmptyCompanies, filterOutEmptyCompanies, anyEmptyCompany, сompaniesCount }
