import { useEffect } from 'react';
import { Form as F, Alert, Button, Col, Container, Row, Spinner } from 'react-bootstrap';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';

import ccmLogoSmall from '@assets/images/CCM_logo_small.png';
import { isApiError, isErrorStatus } from '@abstract'
import { AuthService, CurrentUserService, NavigationService, Toaster } from "@services";
import { CONSTS, ILoadingContext, IUserContext, useCurrentUser, useLoading } from '@utils';

const LoginPage = () => {

  const loadingContext : ILoadingContext = useLoading();
  const userContext : IUserContext = useCurrentUser();

  const navigateToRoot = async () => {
    await NavigationService.navigateToRoot();
  }

  useEffect(() => {
    if (CurrentUserService.isUserAuthenticated()) {
      navigateToRoot();
    }
  },
  // no deps => run once
  [])

  return (
    <Formik
      validateOnChange = {false}
      validateOnBlur = {false}
      initialValues={{
        email: '',
        password: '',
        rememberMe: false
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string().email('Некорректный e-mail').required('Введите e-mail'),
        password: Yup.string().required('Введите пароль'),
        rememberMe: Yup.bool()
      })}
      onSubmit={({ email, password, rememberMe }, { setStatus, setSubmitting }) => {
        loadingContext.setLoading(true);
        setStatus({ isError: false })
        AuthService.login(email, password, rememberMe)
          .then(
            _ => {
              userContext.updateCurrentUser()
                .then(async () => await userContext.updateUserData()
                  .then(_ => {
                    Toaster.makeSuccessToast('Вы успешно авторизовались и сейчас будете перемещены');
                    setTimeout(() => navigateToRoot(), CONSTS.REDIRECT_TIMEOUT_MSEC);
                  })
                  .catch(err => {
                    Toaster.makeErrorToast(CONSTS.ERRORS.GENERIC);
                  })
                  .finally(() => {
                    loadingContext.setLoading(false);
                  }))
            },
            error => {
              loadingContext.setLoading(false);
              setSubmitting(false);
              if (isApiError(error))
              {
                let message;
                switch(error.statusCode)
                {
                  case 403: {
                    message = 'Неверный e-mail или пароль'
                    break;
                  }
                  default: {
                    message = CONSTS.ERRORS.GENERIC
                  }
                }

                setStatus({ isError: true, message: message})
              }
              else throw error;
          }
        );
      }}
      render={({ errors, status, touched, isSubmitting }) => (
        <main className='auth justify-content-center auth-page'>
          <div className='align-self-center auth-block'>
            <div className='card auth-card'>
              <div className='card-body rounded-pill'>
                <Container fluid className='login-card-header'>
                  <Row>
                    <Col sm={8} md={8} lg={8} xl={8}><h3>Личный кабинет заказчика</h3></Col>
                    <Col sm={4} md={4} lg={4} xl={4}><img src={ccmLogoSmall} alt='Личный кабинет заказчика | ССМ' /></Col>
                  </Row>
                </Container>
                <Form noValidate>
                  {(!!Object.keys(errors).length &&
                    Object.keys(errors).map(errorKey => <Alert key={errorKey} variant='danger'>{errors[errorKey]}</Alert>))
                  ||
                  (status && isErrorStatus(status) &&
                    <Alert variant={status.isError ? 'danger' : 'success'}>{status.message}</Alert>)
                  }
                  <F.Group>
                    <Field name='email' type='email' placeholder='Email' className='form-control placeholder-shown' autoFocus='' />
                  </F.Group>
                  <F.Group>
                    <Field name='password' type='password' placeholder='Пароль' className='form-control placeholder-shown' />
                  </F.Group>
                  <F.Group>
                    <Container fluid>
                      <Row>
                        <Col
                          sm={ isSubmitting ? 11 : 12 }
                          md={ isSubmitting ? 11 : 12 }
                          lg={ isSubmitting ? 11 : 12 }
                          xl={ isSubmitting ? 11 : 12 }
                        >
                          <Button className='btn btn-lg btn-primary btn-block' disabled={isSubmitting} type='submit'>Войти</Button>
                        </Col>
                        {loadingContext.isLoading &&
                        <Col sm={1} md={1} lg={1} xl={1}>
                          <Spinner animation='border' role='status'>
                              <span className='sr-only'>Loading...</span>
                          </Spinner>
                        </Col>
                        }
                      </Row>
                    </Container>
                  </F.Group>
                  <F.Group className='text-center'>
                    <div className='custom-control custom-control-inline custom-checkbox'>
                      <Field type='checkbox' id='rememberMe' name='rememberMe' className='custom-control-input' />
                      <label className='custom-control-label' htmlFor='rememberMe'>Оставаться авторизованным?</label>
                    </div>
                  </F.Group>
                </Form>
              </div>
            </div>
          </div>
        </main>
      )}
    />
  )
}

export default LoginPage;
