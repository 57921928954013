import { ILoadingContext, useLoading } from "@utils";

const GlobalLoadingLayer = () => {

  const loadingContext: ILoadingContext = useLoading();

  return (
    <div
      className={'global-loading-layer' + (!loadingContext.isLoading ? ' no-display' : '')}>
    </div>
  )
}

export default GlobalLoadingLayer;
