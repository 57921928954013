import { CONSTS, useCurrentUser } from '@utils';
import { Route, Redirect } from 'react-router-dom';


const AuthBarrier = ({ component: Component, ...rest }) => {
  const userContext = useCurrentUser();

  return (
    <Route {...rest} render={(props) => (
      userContext.isUserAuthenticated() ? <Component {...props} /> : <Redirect to={CONSTS.PATHS.AUTH.LOGIN_PAGE} />
    )} />
  )
};

export default AuthBarrier;
