import { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";

interface IOfflinePaginatedItemsProps<T> {
  items: T[]
  itemsPerPage: number
  renderItem: (item:T, i: number) => JSX.Element
}

const OfflinePaginatedItems = <T extends unknown>(props: IOfflinePaginatedItemsProps<T>) => {

  const [currentItems, setCurrentItems] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);

  useEffect(() => {
    const endOffset = itemOffset + props.itemsPerPage;
    setCurrentItems(props.items.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(props.items.length / props.itemsPerPage));
  }, [itemOffset, props.items, props.itemsPerPage]);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * props.itemsPerPage) % props.items.length;
    setItemOffset(newOffset);
  };

  return (
    <>
      {currentItems && currentItems.map((item, i) => props.renderItem(item, itemOffset + i + 1))}
      <nav>
        <ReactPaginate
          breakLabel='...'
          nextLabel='&raquo;'
          onPageChange={handlePageClick}
          pageRangeDisplayed={5}
          pageCount={pageCount}
          previousLabel='&laquo;'
          renderOnZeroPageCount={null}
          containerClassName='pagination pagination-lg justify-content-center'
          pageClassName='page-item'
          previousClassName='page-item'
          nextClassName='page-item'
          pageLinkClassName='page-link unselectable'
          previousLinkClassName='page-link unselectable'
          breakClassName='page-link unselectable'
          nextLinkClassName='page-link unselectable'
          breakLinkClassName='page-link unselectable'
          activeClassName='active'
          disabledClassName='disabled'
        />
      </nav>
    </>
  )
}

export default OfflinePaginatedItems;
