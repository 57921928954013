import { ApiService, Data } from '@abstract';
import { SessionTokenService } from '@services';
import { CONSTS } from '@utils';

const AuthService = {
  login: async (email: string, password: string, remember: boolean = false) : Promise<void> =>
    await ApiService
      .post<Data<string>>({ url: CONSTS.PATHS.AUTH.API.LOGIN, body: {email: email, password: password}})
      .then(data =>
        remember
          ? SessionTokenService.savePersistently(data.data)
          : SessionTokenService.saveTemporariliy(data.data)),

  logout: async () : Promise<void> => {
    let rawSessionToken = SessionTokenService.getRaw();
    if (!rawSessionToken) {
      return Promise.resolve();
    }

    try {
      return await ApiService.post({ url: CONSTS.PATHS.AUTH.API.LOGOUT, accessToken: rawSessionToken });
    }
    finally {
      return SessionTokenService.clear();
    }
  }
}

export default AuthService;
