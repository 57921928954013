const LayoutSidebar = () =>
  <aside className="app-aside app-aside-light app-aside-expand-md">
    <div className="aside-content">
      <div className="aside-menu overflow-hidden ps ps--active-y">
        <nav id="stacked-menu" className="stacked-menu stacked-menu-has-collapsible">
          <ul className="menu">
            <li key='menu' className="menu-header mt-0">Меню</li>
            <li key='administration' className="menu-item">
              <a className="menu-link is-disabled" href="/#">
                <span className="menu-icon fas fa-users-cog" data-toggle="tooltip" data-title="Администрирование"></span>
                <span className="menu-text">Администрирование</span>
              </a>
            </li>
            <li key='reports' className="menu-item">
              <a className="menu-link" href="/">
                <span className="menu-icon fas fa-calendar-check" data-toggle="tooltip" data-title="Отчёты по компаниям"></span>
                <span className="menu-text">Отчёты</span>
              </a>
            </li>
          </ul>
        </nav>
      </div>
      <div className="ps__rail-x" style={{ "left": '0px', 'bottom': '0px' }}>
        <div className="ps__thumb-x" tabIndex={0} style={{ "left": '0px', 'width': '0px' }}></div>
      </div>
      <div className="ps__rail-y" style={{ "top": '0px', 'right': '0px' }}>
        <div className="ps__thumb-y" tabIndex={0} style={{ "top": '0px', 'height': '0px' }}></div>
      </div>
    </div>
  </aside>

export default LayoutSidebar;
