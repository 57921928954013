import { ILoadingContext, useLoading } from "@utils";
import { Spinner } from "react-bootstrap";

const GlobalLoaderSpinner = () => {

  const loadingContext: ILoadingContext = useLoading();

  return (
    <div className='global-loader-spinner-wrapper'>
      {loadingContext.isLoading &&
        <Spinner
          className='global-loader-spinner'
          animation='border'
          role='status'
        >
          <span className='sr-only'>Loading...</span>
        </Spinner>
      }
    </div>
  )
}


export default GlobalLoaderSpinner;
